/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, typography, icons } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

export const alertIconSvg =
  '<?xml version="1.0" encoding="UTF-8"?><svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!-- Generator: sketchtool 51.3 (57544) - http://www.bohemiancoding.com/sketch --><title>CC94A0C3-EF13-4864-8070-1CD87B400E9C</title><desc>Created with sketchtool.</desc><defs><path d="M27,13.5 C34.425,13.5 40.5,19.575 40.5,27 C40.5,34.425 34.425,40.5 27,40.5 C19.575,40.5 13.5,34.425 13.5,27 C13.5,19.575 19.575,13.5 27,13.5 Z M26.9999996,15.3 C20.4749998,15.3 15.3,20.4749998 15.3,26.9999996 C15.3,33.5249994 20.4749998,38.6999992 26.9999996,38.6999992 C33.5249994,38.6999992 38.6999992,33.5249994 38.6999992,26.9999996 C38.6999992,20.4749998 33.5249994,15.3 26.9999996,15.3 Z M26.0999998,19.125 L27.8999998,19.125 L27.8999998,30.375 L26.0999998,30.375 L26.0999998,19.125 Z M27.0000003,35.0999992 C26.2544158,35.0999992 25.6500002,34.4955836 25.6500002,33.7499992 C25.6500002,33.0044148 26.2544158,32.3999991 27.0000003,32.3999991 C27.7455847,32.3999991 28.3500003,33.0044148 28.3500003,33.7499992 C28.3500003,34.4955836 27.7455847,35.0999992 27.0000003,35.0999992 Z" id="path-1"></path></defs><g id="CASOS-BUY-SECTION---1280px-" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="PDPM-BS14-SoftCategory-AddToCartWhitoutSize-1" transform="translate(-19.000000, -19.000000)"><g id="POPUP" transform="translate(-1.000000, 0.000000)"><g id="icon/alert/alert" transform="translate(7.000000, 6.000000)"><mask id="mask-2" fill="white"><use xlink:href="#path-1"></use></mask><use id="Shape" fill="#BBBBBB" fill-rule="nonzero" xlink:href="#path-1"></use><g id="Color/Silver" mask="url(#mask-2)" fill="#FFC000" fill-rule="nonzero"><g transform="translate(-6.750000, -6.750000)" id="Amber"><rect x="0" y="0" width="67.5" height="67.5"></rect></g></g></g></g></g></g></svg>';

const SelectVariantStyles = css`
  .popup {
    height: calc(100vh - 120px);
    cursor: initial;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;
    @mixin tabletToDesktop{
      height: auto;
      width: 100%;
      min-height: 100%;
    }
    &.stockEnTienda {
      @mixin tabletToDesktop{
        width: 100%;
      }
      .body {
        @mixin mobileToTablet {
          padding: 0 17px;
        }
      }
    }
    &.deliveryOptions {
      @mixin tabletToDesktop{
        width: 100%;
        min-height: 531px;
      }
    }
    .variant-details.mkp {
      justify-content: space-between;
    }
  }
  button.close-button {
    background: none;
    &.hide {
      display: none;
    }
  }
  .store-availability-header {
    display: flex;
    button {
      position: absolute;
      top: 13px;
      right: 12px;
    }
    i.csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.lg};
      @mixin tabletToDesktop{
        font-size: ${typography.sm};
      }
    }

  }
  .header {
    padding: 11px 20px 9.75px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.gray.shade14};
    @mixin tabletToDesktop{
      height: 50px;
      padding: 0 20px;
    }

    &.deliveryOptions {
      @mixin tabletToDesktop{
        height: 71px;
        padding: 26px 26px 19px 38px;
      }
      @mixin mobileToTablet {
        height: 83px;
        padding: 36px 0 24px 16px;
      }
    }
    .csicon-icon-success-circle {
      color: ${colors.green.shade1};
      font-size: ${typography.xl3a};
    }

    .label {
      flex-grow: 1;
      margin: 0 14px;
      line-height: 24px;
      &.deliveryOptions {
        margin-left: 21.5px;
        @mixin mobileToTablet {
          margin: 0 0 0 16px;
          line-height: 26px;
        }
      }
    }

    .csicon-danger {
      color: ${colors.gray.shade29};
      font-size: ${typography.xs2};
      @mixin tabletToDesktop{
        font-size: ${typography.sm};
      }
    }

    .warning-icon {
      width:55px;
      height:27px;
      background-image: url("${svgToDataUri(alertIconSvg)}");
      background-size: contain;
      background-repeat: no-repeat;
      @mixin tabletToDesktop{
        width: 27px;
      }
    }

    .homeDelivery-icon {
      width:32px;
      height:32px;
      background-image: url("${svgToDataUri(icons.deliveryIconRebranded)}");
      background-size: contain;
      background-repeat: no-repeat;
    }

    .pickupInStore-icon {
      width:32px;
      height:32px;
      background-image: url("${svgToDataUri(
        icons.storeWithdrawalIconRebranded
      )}");
      background-size: contain;
      background-repeat: no-repeat;
      @mixin mobileToTablet {
        margin-bottom: 8px;
      }
    }
  }

  .body {
    overflow-x: hidden;
    padding: 0 27px;
    flex-grow: 1;

    @mixin tabletToDesktop{
      height: 426px;
    }

    .collections,
    .bundles {
      border-top: 1px solid ${colors.gray.shade14};

      span {
        margin: 22px 0;
        display: block;
      }
    }

    .collections {
      padding-top: 22px;
    }

    &.deliveryOptions {
      padding: 0 17px;
    }
  }

  .product-details {
    display: flex;
    border-bottom: 1px solid ${colors.gray.shade14};
    padding: 24px 0 18px;

    .product-image {
      box-sizing: border-box;
      height: 89px;
      width: 89px;
      border: 1px solid ${colors.gray.shade43};
      @mixin tabletToDesktop{
        height: 46px;
        width: 46px;
      }
    }
    .item-info {
      margin: 0 18px;
      @mixin tabletToDesktop {
        width: 38%;
        margin: 0 0 0 18px;
      }
    }
    .info-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      @mixin tabletToDesktop{
        flex-direction: row;
      }
    }

    &.deliveryOptions {
      padding: 24px 0;
      @mixin tabletToDesktop {
        height: 82px;
        padding: 16px 0 21px;
      }
    }
  }

  .price {
    margin: 21px 18px 0;
    @mixin tabletToDesktop{
      margin: 0;
    }
    &.mkp {
      margin: 5.25px 9px 0;
    }
    &.mt-8p {
      margin-top: 8px;
    }
    &.mt-0 {
      margin-top: 0;
    }
  }

  .variant-details {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    @mixin tabletToDesktop{
      flex-direction: row;
    }

    .cual-buscas {
      margin-top: 24px;
      margin-bottom: 9px;
      line-height: 22px;
    }

    .color-variants {
      width: 100%;
      margin-bottom: 14px;
      margin-top: 15px;
      @mixin tabletToDesktop{
        width: 33%;
      }

      &.deliveryOptions {
        line-height: 15px;
        @mixin tabletToDesktop{
          width: 50%;
          margin: 0 10px 0 0;
        }
      }
    }
    .size-variants {
      width: 100%;
      margin: 0;
      @mixin tabletToDesktop{
        width: 33%;
        margin: 0 10px 0 28px;
      }
      &.deliveryOptions {
        @mixin tabletToDesktop{
          width: 50%;
          margin: 15px 0 0 0;
        }
      }
      &.enhanced-size-selector {
        @mixin tabletToDesktop{
          width: 284px
        }
      }
    }
    &.mkp{
      justify-content: flex-start;

      .size-variants {
        @mixin tabletToDesktop{
          margin: 15px 0 0 0;
        }
        &.deliveryOptions {
          @mixin tabletToDesktop{
            margin: 15px 0 0 0;
          }
        }
      }
    }
    .addToCart-container {
      width: 190px;
      background-color: ${colors.white.shade1};
      padding-top: 23px;

      &.mkp {
        width: 33%;
        display: flex;
        justify-content: flex-end;
        @mixin tabletToDesktop{
          width: auto;
        }
      }

      @mixin mobileToTablet {
        position: fixed;
        padding: 25px;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &.mkp {
          font-size: ${typography.base};
          border-top: 1px solid #F0F0F0;
          height: 112px;
          width: 100%;
          padding: 0 24px 0 25px;
          justify-content: center;
          background-color: ${colors.gray.shade51};
        }
      }
    }
  }
  .stock-en-tienda-option-text {
    font-size: ${typography.xl};
    margin: 24px 0 10px 0;
    line-height: 21.6px;
  }
  .see-availability-container {
    display: flex;
    @mixin tabletToDesktop {
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 72px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      background: ${colors.gray.shade41};
      text-align: right;
      border-top: 1px solid ${colors.gray.shade14};
    }

    @mixin mobileToTablet {
      text-align: center;
      background-color: ${colors.white.shade1};
      box-shadow: 0 0 20px rgb(0 0 0 / 20%);
      justify-content: center;
      flex-direction: row;
      line-height: 18px;
      padding: 12px 0;
      position: fixed;
      bottom: 0;
      left: 0;
      width: -webkit-fill-available;
      display: flex;
    }

    .availability-btn-container {
      width: 207px;
      display: flex;
      align-items: center;
      @mixin tabletToDesktop {
        margin-left: 14px;
      }
    }
  }
`;

const closeButtonStyles = css`
  .cerrar-button {
    margin-bottom: 2px;
    &.float {
      margin-top: 13px;
      font-size: ${typography.base};
      @mixin tabletToDesktop {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-right: 30px;
        height: 63px;
        margin-bottom: 0;
        background: ${colors.gray.shade41};
        text-align: right;
        border-top: 1px solid ${colors.gray.shade14};
      }
    }
    .cerrar-button-text {
      color: ${colors.black.shade1};
      font-size: 14px;
      letter-spacing: 0.19px;
      line-height: 17px;
      padding-bottom: 1px;
      text-decoration: underline;
      text-underline-position: under;
      margin-right: 14.5px;
      @mixin mobileToTablet {
        color: ${colors.black.shade9};
      }
    }
  }
`;

export { SelectVariantStyles, closeButtonStyles };
