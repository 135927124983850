import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { SizeButtonStyles } from './SizeButton.style';

const SizeButton = ({
  value,
  available,
  index,
  selectedSize,
  onSizeChange,
  isRebrandingEnabled,
  theme,
  enhancedSizeSelectorClassName
}) => {
  const onClickHandler = () => {
    onSizeChange(value, index);
  };

  const isSelectedSize = (val) =>
    selectedSize && val.toUpperCase() === selectedSize.toUpperCase();

  const themedStyles = SizeButtonStyles(theme);

  return (
    <button
      id={`testId-sizeButton-${value}`}
      type="button"
      key={value}
      className={`size-button ${isRebrandingEnabled &&
        'rebranded'} ${enhancedSizeSelectorClassName} ${
        isSelectedSize(value) ? 'size-button-selected' : ''
      } ${!available ? 'size-button-unavailable' : ''} `}
      onClick={onClickHandler}
      disabled={!available}
    >
      {value}
      <style jsx>{themedStyles}</style>
    </button>
  );
};

SizeButton.defaultProps = {
  isRebrandingEnabled: false,
  theme: null,
  enhancedSizeSelectorClassName: ''
};

SizeButton.propTypes = {
  value: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  selectedSize: PropTypes.string.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  isRebrandingEnabled: PropTypes.bool,
  theme: PropTypes.object,
  enhancedSizeSelectorClassName: PropTypes.string
};

export default withTheme(SizeButton);
