import css from 'styled-jsx/css';

const SponsoredDisplayStyles = css`
  .banner-styles {
    display: flex;
    gap: 26px;
    margin: 12px 0 0 0;
    width: 100%;
  }

  .mobile-banner-styles {
    margin-top: 16px;
  }
`;

export { SponsoredDisplayStyles };
