import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import noop from '../../utils/noop';
import SizeButtons from './SizeButtons/SizeButtons';
import SizeDropdown from './SizeDropdown/SizeDropdown';

const Size = ({
  sizes,
  selectedSize,
  onSizeChange,
  title,
  type,
  variant,
  isSizeChartHidden,
  sizeChartData,
  fomo
}) => {
  return (
    <Fragment>
      {type === 'dropdown' ? (
        <SizeDropdown
          sizes={sizes}
          selectedSize={selectedSize}
          onSizeChange={onSizeChange}
          title={title}
          variant={variant === 'cart' && 'medium'}
        />
      ) : (
        <SizeButtons
          sizes={sizes}
          selectedSize={selectedSize}
          onSizeChange={onSizeChange}
          title={title}
          isSizeChartHidden={isSizeChartHidden}
          sizeChartData={sizeChartData}
          fomo={fomo}
        />
      )}
    </Fragment>
  );
};

Size.defaultProps = {
  sizes: [],
  onSizeChange: noop,
  title: 'Selection Talla',
  type: '',
  selectedSize: undefined,
  variant: '',
  isSizeChartHidden: false,
  sizeChartData: {},
  fomo: {}
};

Size.propTypes = {
  sizes: PropTypes.arrayOf(PropTypes.object),
  onSizeChange: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  selectedSize: PropTypes.string,
  variant: PropTypes.string,
  isSizeChartHidden: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object
};

export default Size;
