import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { SponsoredDisplayStyles } from './SponsoredDisplay.style';

const SponsoredDisplayPDP = dynamic(
  () =>
    import('@digital-retail/sponsored-provider-library').then(
      (mod) => mod.SponsoredDisplayPDP
    ),
  { ssr: false }
);

const SponsoredBannerDisplay = ({
  store,
  horizontalProduct,
  verticalProduct,
  isMobile,
  onBannerClick
}) => {
  if (isMobile) {
    return (
      <div className="mobile-banner-styles">
        <SponsoredDisplayPDP
          store={store}
          product={verticalProduct}
          onBannerClick={onBannerClick}
        />
        <style jsx>{SponsoredDisplayStyles}</style>
      </div>
    );
  }

  return (
    <div className="banner-styles">
      <SponsoredDisplayPDP
        store={store}
        product={horizontalProduct}
        onBannerClick={onBannerClick}
        containerStyles={{ flex: 1 }}
      />
      <SponsoredDisplayPDP
        store={store}
        product={verticalProduct}
        onBannerClick={onBannerClick}
        containerStyles={{ flex: 1 }}
      />
      <style jsx>{SponsoredDisplayStyles}</style>
    </div>
  );
};
SponsoredBannerDisplay.propTypes = {
  store: PropTypes.string.isRequired,
  horizontalProduct: PropTypes.object.isRequired,
  verticalProduct: PropTypes.object.isRequired,
  onBannerClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default SponsoredBannerDisplay;
