import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../utils/noop';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withProduct } from '../../../utils/ProductContext';
import Drawer from '../../ui/Drawer/Drawer';
import { withLabels } from '../../../utils/LabelsContext';
import { SizeButtonStyles } from './SizeButtons.style';
import SizeButton from './SizeButton/SizeButton';
import SizeChartButton from './SizeChartButton';
import SizeEquivalencies from './SizeEquivalencies/SizeEquivalencies';
import _ from '../../../utils/LodashImports';
import { withSizeInfo } from '../../../utils/SizeInfoContext';
import { withDigitalData } from '../../../utils/DigitalDataContext';
import DrawerTab from '../../ui/DrawerTab/DrawerTab';

const SizeButtons = ({
  product,
  appCtx,
  labels,
  sizes,
  selectedSize,
  onSizeChange,
  title,
  isSizeChartHidden,
  sizeInfo,
  digitalData,
  sizeChartData,
  fomo
}) => {
  const [isDrawerActive, activateDrawer] = useState(false);
  const [isSizeChartLoading, setSizeChartLoaderVisibility] = useState(true);
  const displaySizeChartBtnRef = useRef();
  const { sizeChart } = product.attributes;
  const { sizeChartName, sizeChartUrl } = sizeChart || {};
  const { regionCode, isRebrandingEnabled } = appCtx;
  const currentVariant = _.get(product, 'currentVariant', '');

  const guideSymbol = _.get(product, 'guideSymbol', false);
  const sizeEquivalencieToggle = _.get(
    appCtx,
    'siteConfig.toggles.isSizeEquivalencyTableVisible',
    false
  );
  const isEnhancedSizeSelectorActive = _.get(
    appCtx,
    'siteConfig.toggles.isEnhancedSizeSelectorActive',
    true
  );
  const isSizeChartDataVisible = _.get(
    appCtx,
    'siteConfig.toggles.isSizeChartDataVisible',
    true
  );
  const isFomoBadgeVisible = _.get(
    appCtx,
    'siteConfig.toggles.isFomoBadgeVisible',
    true
  );
  const fomoMessage = _.get(
    appCtx,
    'siteConfig.textDictionary.FOMO_MESSAGE',
    'Últimas unidades'
  );
  const enhancedSizeSelectorClassName = isEnhancedSizeSelectorActive
    ? 'enhanced-size-selector'
    : '';

  useEffect(() => {
    if (!displaySizeChartBtnRef.current) {
      return;
    }
    const sizeChartBtnInnerText = (
      displaySizeChartBtnRef.current.innerText || ''
    ).toLowerCase();
    if (sizeChartBtnInnerText.includes(labels.SIZE_CHART.toLowerCase())) {
      digitalData.pdp.setProductSizeTools('1');
    } else if (sizeChartBtnInnerText.includes('encuentra tu talla')) {
      digitalData.pdp.setProductSizeTools('2');
    }
  }, [displaySizeChartBtnRef]);

  const SizeEquivalenciesTable = () =>
    sizes.map(({ value, available }, index) => (
      <SizeEquivalencies
        value={value}
        sizeMap={sizeInfo && sizeInfo[value]}
        available={available}
        index={index}
        onSizeChange={onSizeChange}
        selectedSize={selectedSize}
        regionCode={regionCode}
        isRebrandingEnabled={isRebrandingEnabled}
      />
    ));

  const SizeButtonsTable = () =>
    sizes
      .sort((a, b) => {
        const valueA = a.value && a.value.split(' ');
        const valueB = b.value && b.value.split(' ');
        if (valueA && valueB && valueA.length > 1 && valueB.length > 1) {
          return a.value.split(' ')[0] - b.value.split(' ')[0];
        }
        return 0;
      })
      .map(({ value, available }, index) => (
        <SizeButton
          value={value}
          available={available}
          index={index}
          selectedSize={selectedSize}
          onSizeChange={onSizeChange}
          isRebrandingEnabled={isRebrandingEnabled}
          enhancedSizeSelectorClassName={enhancedSizeSelectorClassName}
        />
      ));

  const isActiveTabAssistentePersonal = _.get(
    appCtx,
    'siteConfig.toggles.isActiveTabAssistentePersonal',
    false
  );

  const sizeDrawerTitle = _.get(
    labels,
    'FIND_YOUR_SIZE',
    'ENCUENTRA TU TALLAS'
  );

  const tabHeadings = {
    0: _.get(labels, 'PERSONAL_ASSISTANT', 'Asistente personal'),
    1: _.get(labels, 'SIZE_GUIDE', 'Guía de tallas')
  };

  const formattedSizeChartData =
    sizeChartData && sizeChartData.sizeChart
      ? Object.entries(sizeChartData.sizeChart)
          .map(([key, value]) =>
            key === 'cm' ? `${value} centímetros` : `${key} ${value}`
          )
          .join(' - ')
      : '';

  const isFomoBadgeEnabled =
    isEnhancedSizeSelectorActive &&
    isFomoBadgeVisible &&
    selectedSize &&
    fomo?.enabled;

  useEffect(() => {
    function dispatchCustomEvent(eventName, payload) {
      const event = new CustomEvent(eventName, {
        bubbles: true,
        detail: { payload }
      });
      window.dispatchEvent(event);
    }

    if (currentVariant && formattedSizeChartData) {
      dispatchCustomEvent(
        'DDPDPShowSizeChartData',
        `${currentVariant}|${formattedSizeChartData}`
      );
    } else {
      dispatchCustomEvent('DDPDPShowSizeChartData', '');
    }

    if (currentVariant && isFomoBadgeEnabled) {
      dispatchCustomEvent(
        'DDPDPShowFomoMessage',
        `${currentVariant}|${fomoMessage}`
      );
    } else {
      dispatchCustomEvent('DDPDPShowFomoMessage', '');
    }
  }, [currentVariant, formattedSizeChartData, isFomoBadgeEnabled]);

  return (
    <div
      className={`size-container fa--sizes ${isRebrandingEnabled &&
        'rebranded'} ${enhancedSizeSelectorClassName}`}
    >
      <div>
        <div className={`title-container  ${enhancedSizeSelectorClassName}`}>
          <span className={`size-title  ${enhancedSizeSelectorClassName}`}>
            {title}
          </span>
          {isFomoBadgeEnabled && (
            <div className="fomo-badge">{fomoMessage}</div>
          )}
          {!isEnhancedSizeSelectorActive && (
            <SizeChartButton
              sizeChartName={sizeChartName}
              isSizeChartHidden={isSizeChartHidden}
              activateDrawer={activateDrawer}
              displaySizeChartBtnRef={displaySizeChartBtnRef}
              guideSymbol={guideSymbol}
              labels={labels}
              isEnhancedSizeSelectorActive={isEnhancedSizeSelectorActive}
            />
          )}
        </div>
        {isSizeChartDataVisible && (
          <div className="size-chart-data" data-testid="size-chart-data">
            {selectedSize && formattedSizeChartData}
          </div>
        )}
      </div>
      <div className={`size-options  ${enhancedSizeSelectorClassName}  `}>
        {sizeInfo && sizeEquivalencieToggle ? (
          <SizeEquivalenciesTable />
        ) : (
          <SizeButtonsTable />
        )}
      </div>
      {sizeChartName && sizeChartUrl && (
        <Drawer
          toggleState={[isDrawerActive, activateDrawer]}
          heading={sizeDrawerTitle}
          isLoading={isSizeChartLoading}
          onOpen={() => {
            if (isActiveTabAssistentePersonal) {
              setSizeChartLoaderVisibility(false);
            } else {
              setSizeChartLoaderVisibility(true);
            }
          }}
        >
          <DrawerTab
            onOpenSizeChart={() => setSizeChartLoaderVisibility(true)}
            onLoad={() => {
              setSizeChartLoaderVisibility(false);
            }}
            sizeChartName={sizeChartName}
            sizeChartUrl={sizeChartUrl}
            regionCode={regionCode}
            isActiveTabAssistentePersonal={isActiveTabAssistentePersonal}
            tabHeadings={tabHeadings}
          />
        </Drawer>
      )}
      {isEnhancedSizeSelectorActive && (
        <SizeChartButton
          sizeChartName={sizeChartName}
          isSizeChartHidden={isSizeChartHidden}
          activateDrawer={activateDrawer}
          displaySizeChartBtnRef={displaySizeChartBtnRef}
          guideSymbol={guideSymbol}
          labels={labels}
          isEnhancedSizeSelectorActive={isEnhancedSizeSelectorActive}
        />
      )}
      <style jsx>{SizeButtonStyles}</style>
    </div>
  );
};

SizeButtons.defaultProps = {
  sizes: [],
  onSizeChange: noop,
  title: 'Selection Talla',
  selectedSize: undefined,
  isSizeChartHidden: false,
  sizeInfo: undefined,
  sizeChartData: {},
  fomo: {}
};

SizeButtons.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object),
  onSizeChange: PropTypes.func,
  title: PropTypes.string,
  selectedSize: PropTypes.string,
  isSizeChartHidden: PropTypes.bool,
  sizeInfo: PropTypes.object,
  digitalData: PropTypes.object.isRequired,
  sizeChartData: PropTypes.object,
  fomo: {}
};

export default withSizeInfo(
  withProduct(withLabels(withApplicationContext(withDigitalData(SizeButtons))))
);
export { SizeButtons };
